import {formatMoney} from "../utilities/Currency";

const productPriceUpdate = () => {
  const options = document.querySelectorAll('.subscription-options__group');
  const priceListContainer = document.querySelector('.product-meta__price-list-container');
  if (!priceListContainer) {
    return;
  }
  const savings = priceListContainer.querySelector('.label.label--highlight');
  let compare_at_price = priceListContainer.querySelector('.price--compare');
  let hidden_compare = priceListContainer.querySelector('.price--hidden-compare');
  let percentageSaving;

  if (hidden_compare) {
    hidden_compare.innerText = document.querySelector('[data-ot-compare-at-price]').innerText;
  }

  options.forEach(option => {
    if (option.classList.contains('active')) {
      const optionPrice = option.querySelector('strong');
      const mainPrice = priceListContainer.querySelector('.price--large');
      mainPrice.innerText = optionPrice.innerText;
    }
  });

  // Variants
  document.addEventListener("updatePrice_Dispatch", (e) => {
    console.log("updatePrice_Dispatch: ", e.detail);
    const price = priceListContainer.querySelector('.price--large');
    compare_at_price = priceListContainer.querySelector('.price--compare');

    if (compare_at_price) {
      compare_at_price.innerText = `${formatMoney(e.detail.compare_at_price, window.themeVariables.settings.moneyFormat, true)}`;
    } else {
      hidden_compare.innerText = formatMoney(e.detail.price, window.themeVariables.settings.moneyFormat, true);
    }

    options.forEach(option => {
      if (option.classList.contains('active')) {
        if (option.hasAttribute('data-one-time')) {
          price.innerText = `${formatMoney(e.detail.price, window.themeVariables.settings.moneyFormat, true)}`;

          if (e.detail.compare_at_price != null) {
            percentageSaving = `save ${Math.floor((e.detail.compare_at_price - e.detail.price) / (e.detail.price) * 100)}%`;
          } else {
            percentageSaving = "";
            hidden_compare.innerText = "";
          }

        } else {
          price.innerText = `${formatMoney(e.detail.selling_plan_price, window.themeVariables.settings.moneyFormat, true)}`;

          if (e.detail.compare_at_price != null ) {
            percentageSaving = `save ${Math.floor((e.detail.compare_at_price - e.detail.selling_plan_price) / e.detail.compare_at_price * 100)}%`;
            document.querySelector('[data-subscription-savings] span').innerText = Math.floor((e.detail.compare_at_price - e.detail.selling_plan_price) / e.detail.compare_at_price * 100);
          } else {
            percentageSaving = `save ${Math.floor((e.detail.price - e.detail.selling_plan_price) / e.detail.price * 100)}%`;
            document.querySelector('[data-subscription-savings] span').innerText = Math.floor((e.detail.price - e.detail.selling_plan_price) / e.detail.price * 100);
          }
        }

        if (!compare_at_price) {
          //console.log("Option: ", option);
        }

        savings.innerText = percentageSaving;
      } 
    });
  });

  // Subscription radios
  document.addEventListener("handleRadioChange_Dispatch", (e) => {
    //console.log("handleRadioChange_Dispatch: ", e.detail);
    compare_at_price = priceListContainer.querySelector('.price--compare');
    const price = priceListContainer.querySelector('.price--large');
    let base_price = parseFloat(e.detail.price.replace(/[^0-9\.-]+/g,""));
    let base_compare_at_price = parseInt(e.detail.compareAtPrice.replace(/[^0-9\.-]+/g,""));
    
    price.innerText = e.detail.price;

    if (compare_at_price) {
      compare_at_price.innerText = e.detail.compareAtPrice;
      
      if (e.detail.isOneTime) {
        percentageSaving = `save ${Math.floor((base_compare_at_price - base_price) / base_compare_at_price * 100)}%`;
        savings.innerText = percentageSaving;
      } else {
        percentageSaving = `save ${Math.floor((base_compare_at_price - base_price) / base_compare_at_price * 100)}%`;
        savings.innerText = percentageSaving;
      }
    } else {
      if (e.detail.isOneTime) {
        savings.innerText = "";
        hidden_compare.innerHTML = "";

        if (compare_at_price) {
          compare_at_price.innerText = "";
        }

      } else {
        percentageSaving = `save ${Math.floor((base_compare_at_price - base_price) / base_compare_at_price * 100)}%`;
        savings.innerText = percentageSaving;
        document.querySelector('[data-ot-compare-at-price]').innerText = `${e.detail.compareAtPrice} -4`;
        hidden_compare.innerText = e.detail.compareAtPrice;
      }
    }
  });
}
export default productPriceUpdate;
